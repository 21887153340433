export interface Socket {
  id: number;
  name: string;
}

export interface Manufacturer {
  id: number;
  name: string;
  img?: string;
  model_img?: string;
  models: Model[];
}
export interface MeterOption {
  id: number;
  name: string;
}
export interface Model {
  id: number;
  name: string;
  img?: string;
  configs: MeterConfig[];
}
export interface MeterConfig {
  option: MeterOption;
  socket: Socket;
  countries: string[];
  advice: Advice;
}

export interface Advice {
  id?: number;
  advice: string;
  link?: string;
  img?: string;
}

let advice_powu = {
  advice: "Pow-U with RJ45 cable",
  link: "https://www.amsleser.no/home/7-29-pow-u-han-port-reader-for-m-bus-power-meters.html#/23-enclosure-with/25-cable-rj45_15_cm",
  img: "https://www.amsleser.no/50-large_default/pow-u-han-port-reader-for-m-bus-power-meters.jpg",
};

let advice_powu12 = {
  advice: "Pow-U with RJ12 cable",
  link: "https://www.amsleser.no/home/7-33-pow-u-han-port-reader-for-m-bus-power-meters.html#/23-enclosure-with/27-cable-rj12_30_cm",
  img: "https://www.amsleser.no/50-large_default/pow-u-han-port-reader-for-m-bus-power-meters.jpg",
};

let advice_powk = {
  advice: "Pow-K",
  link: "https://www.amsleser.no/home/1-27-pow-k-han-port-reader-for-kamstrup-omnipower-meters.html#/20-3d_printed_holder-with",
  img: "https://www.amsleser.no/75-large_default/pow-k-han-port-reader-for-kamstrup-omnipower-meters.jpg",
};

let advice_powp1 = {
  advice: "Pow-P1",
  link: "https://www.amsleser.no/home/10-39-pow-p1-reader-for-power-meter-with-p1-port.html#/23-enclosure-with/27-cable-rj12_30_cm",
  img: "https://www.amsleser.no/80-large_default/pow-p1-reader-for-power-meter-with-p1-port.jpg",
};

let id=100;

let socket_rj11_12 = { id: id++, name: "RJ11/12" };
let socket_rj45 = { id: id++, name: "RJ45 (Ethernet)" };
let socket_6pin = { id: id++, name: "6-pin plug in expansion bay" };

let option_none = { id: id++, name: "None" };
let option_built_in = { id: id++, name: "Built-in HAN interface" };
let option_op027a = { id: id++, name: "OP027a HAN interface" };
let option_politerm = { id: id++, name: "Politerm800" };
let option_han_activated = { id: id++, name: "HAN port is activated or confirmed with grid company" };
let option_han_not_activated = { id: id++, name: "I do not know if the HAN port can be activated" };

// ACTUAL DATASET, bugs are expected. Needs to be verified with Excel sheet.
/**
 * Actual normalized Dataset
 */
export const manufacturers: Manufacturer[] = [
  {
    id: id++,
    name: "Aidon",
    img: "aidon.png",
    model_img: "aidon-model.png",
    models: [
      {
        id: id++,
        name: "6442",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj11_12,
            countries: ["Sweden"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "6490",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj11_12,
            countries: ["Sweden"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "6492",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj11_12,
            countries: ["Sweden"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "7410",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj11_12,
            countries: ["Finland"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "6483",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "6474",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "6475",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "6476",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "6477",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "6479",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "6484",
        configs: [
          {
            option: option_built_in,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "Politerm 831",
        configs: [
          {
            option: option_op027a,
            socket: socket_rj45,
            countries: ["Norway", "Sweden"],
            advice: advice_powu,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "Iskraemeco",
    img: "iskrameco.png",
    models: [
      {
        id: id++,
        name: "AM550",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            // Not all grid distributors in Slovenia are currently covered, new ones will be added as we receive documentation. Currently confirmed to work with Elektro Gorenjska
            countries: ["Austria", "Slovenia (Elektro Gorenjska confirmed, support for other operators will be added if documentation is supplied)"], 
            advice: advice_powp1,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "Kaifa/Nuri",
    img: "kaifa.png",
    models: [
      {
        id: id++,
        name: "MA105H2E",
        configs: [
          {
            option: option_none,
            socket: socket_rj45,
            countries: ["Norway"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "MA304H3E",
        configs: [
          {
            option: option_none,
            socket: socket_rj45,
            countries: ["Norway"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "MA304H4",
        configs: [
          {
            option: option_none,
            socket: socket_rj45,
            countries: ["Norway"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "MA304T3",
        configs: [
          {
            option: option_none,
            socket: socket_rj45,
            countries: ["Norway"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "MA304T4",
        configs: [
          {
            option: option_none,
            socket: socket_rj45,
            countries: ["Norway"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "MA309M",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: advice_powu12,
          },
        ],
      },
      {
        id: id++,
        name: "MA110M",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: advice_powu12,
          },
        ],
      },
      {
        id: id++,
        name: "MA309M",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: advice_powu12,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "Kamstrup",
    img: "kamstrup.png",
    models: [
      {
        id: id++,
        name: "Omnia E-meter",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Denmark", "Sweden"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "Omnipower 684xx2",
        configs: [
          {
            option: option_none,
            socket: socket_6pin,
            countries: ["Norway", "Sweden", "Denmark", "Switzerland"],
            advice: advice_powk,
          },
        ],
      },
      {
        id: id++,
        name: "Omnipower 684xx3",
        configs: [
          {
            option: option_none,
            socket: socket_6pin,
            countries: ["Norway", "Sweden", "Denmark", "Switzerland"],
            advice: advice_powk,
          },
        ],
      },
      {
        id: id++,
        name: "Omnipower 685xx2",
        configs: [
          {
            option: option_none,
            socket: socket_6pin,
            countries: ["Norway", "Sweden", "Denmark", "Switzerland"],
            advice: advice_powk,
          },
        ],
      },
      {
        id: id++,
        name: "Omnipower 685xx3",
        configs: [
          {
            option: option_none,
            socket: socket_6pin,
            countries: ["Norway", "Sweden", "Denmark", "Switzerland"],
            advice: advice_powk,
          },
        ],
      },
      {
        id: id++,
        name: "Omnipower 686xx1",
        configs: [
          {
            option: option_none,
            socket: socket_6pin,
            countries: ["Norway", "Sweden", "Denmark", "Switzerland"],
            advice: advice_powk,
          },
        ],
      },
      {
        id: id++,
        name: "382M",
        configs: [
          {
            option: option_none,
            socket: socket_6pin,
            countries: ["Denmark", "Austria"],
            advice: advice_powk
          }
        ]
      }
    ],
  },
  {
    id: id++,
    name: "Landis + Gyr",
    img: "landis.png",
    models: [
      {
        id: id++,
        name: "E350",
        configs: [
          {
            option: option_politerm,
            socket: socket_rj45,
            countries: ["Norway"],
            advice: advice_powu,
          },
        ],
      },
      {
        id: id++,
        name: "E360",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Sweden", "Denmark"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "E450 S4",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Austria", "Switzerland", "Austria: Netz Burgendland"],
            advice: advice_powu12,
          },
        ],
      },
      {
        id: id++,
        name: "E570",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Switzerland"],
            advice: advice_powu12,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "NES",
    img: "nes.png",
    models: [
      {
        id: id++,
        name: "G5 83335-XXX",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Denmark"],
            advice: advice_powp1,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "Sagemcom",
    img: "sagemcom.jpg",
    models: [
      {
        id: id++,
        name: "S211",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Sweden"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "T211",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Sweden"],
            advice: advice_powp1,
          },
        ],
      },
      {
        id: id++,
        name: "T210-D",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: advice_powu12,
          },
        ],
      },
      {
        id: id++,
        name: "S210",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: advice_powu12,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "Sanxing",
    img: "sanxing.webp",
    models: [
      {
        id: id++,
        name: "S34U18",
        configs: [
          {
            option: option_none,
            socket: socket_rj11_12,
            countries: ["Sweden", "Hungary"],
            advice: advice_powp1,
          },
        ],
      },
    ],
  },
  {
    id: id++,
    name: "Siemens",
    img: "siemens.png",
    models: [
      {
        id: id++,
        name: "IM350/150",
        configs: [
          {
            option: option_han_activated,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: advice_powp1
          },
          {
            option: option_han_not_activated,
            socket: socket_rj11_12,
            countries: ["Austria"],
            advice: { advice: "Please contact your grid company to check if HAN port can be activated on your meter" }
          }
        ]
      }
    ]
  }
];
